import * as echarts from 'echarts';

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AppContext from 'context/Context';
import CustomInfoCard from 'components/anopseo/common/CustomInfoCard';
import EChartsReactCore from 'echarts-for-react/lib/core';
import { Spinner } from 'react-bootstrap';
import { getColor } from 'helpers/utils';
import { usePDF } from 'react-to-pdf';
import { useTranslation } from 'react-i18next';

const PageAnalysisDetailsAudit = () => {
  const {
    config: { token }
  } = useContext(AppContext);
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { toPDF, targetRef } = usePDF({ filename: 'audit.pdf' });
  const [data, setData] = useState({});

  const fetchData = useCallback(async () => {
    const pagesResponse = await fetch(
      `${process.env.REACT_APP_API_URL}analyzes/pages/${params.id}`,
      {
        headers: { Authorization: 'Bearer ' + token }
      }
    );
    const json = await pagesResponse.json();
    setData(json);
  }, []);

  useEffect(() => {
    const body = document.querySelector('body');
    body.style.overflow = 'hidden';

    let timeout;
    fetchData()
      .then(() => {
        timeout = setTimeout(() => {
          const audit = document.querySelector('#audit');
          const height = 1122;
          let usedHeight = 0;

          let container;

          document.querySelectorAll('#audit > :not(section)').forEach(child => {
            if (usedHeight == 0) {
              container = audit.appendChild(document.createElement('section'));
              container.style.height = `${height}px`;
            }
            const childHeight = child.getBoundingClientRect().height;
            if (usedHeight + childHeight >= height - 32) {
              container = audit.appendChild(document.createElement('section'));
              container.style.height = `${height}px`;
              usedHeight = 0;
            }
            container.appendChild(child);
            usedHeight += childHeight;
          });

          toPDF();
          navigate(`/page-analysis/${params.id}`);
          body.style.overflow = 'auto';
        }, 2000);
      })
      .catch(error => console.warn(error));

    return () => {
      clearTimeout(timeout);
    };
  }, [fetchData]);

  const analysisData = data?.analysisData?.data;

  const scoreColor =
    analysisData?.overview?.score > 30
      ? analysisData?.overview?.score > 70
        ? 'success'
        : 'warning'
      : 'danger';

  const scoreChartOption = {
    series: [
      {
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        pointer: {
          show: false
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            color: getColor(scoreColor)
          }
        },
        axisLine: {
          lineStyle: {
            width: 8,
            color: [[1, getColor('gray-200')]]
          }
        },
        splitLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        data: [
          {
            value: analysisData?.overview?.score,
            detail: {
              offsetCenter: ['4%', '4%']
            }
          }
        ],
        detail: {
          formatter: '{value}%'
        }
      }
    ]
  };

  const contentRatioColor =
    parseFloat(analysisData?.content?.content_ratio?.display_value) > 30
      ? parseFloat(analysisData?.content?.content_ratio?.display_value) > 70
        ? 'danger'
        : 'warning'
      : 'success';
  const keywordQtyColor =
    parseInt(analysisData?.content?.keyword_qty?.display_value) > 167
      ? parseInt(analysisData?.content?.keyword_qty?.display_value) > 333
        ? 'danger'
        : 'warning'
      : 'success';
  const contentRatioOption = {
    series: [
      {
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        max: 100,
        pointer: {
          show: false
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            color: getColor(contentRatioColor)
          }
        },
        axisLine: {
          lineStyle: {
            width: 8,
            color: [[1, getColor('gray-200')]]
          }
        },
        splitLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        data: [
          {
            value: parseFloat(
              analysisData?.content?.content_ratio?.display_value
            ),
            detail: {
              offsetCenter: ['4%', '4%']
            }
          }
        ]
      }
    ]
  };
  const keywordQtyOption = {
    series: [
      {
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        max: 500,
        pointer: {
          show: false
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            color: getColor(keywordQtyColor)
          }
        },
        axisLine: {
          lineStyle: {
            width: 8,
            color: [[1, getColor('gray-200')]]
          }
        },
        splitLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        data: [
          {
            value: parseInt(analysisData?.content?.keyword_qty?.display_value),
            detail: {
              offsetCenter: ['4%', '4%']
            }
          }
        ]
      }
    ]
  };

  const headingsOption = {
    series: [
      {
        type: 'bar',
        data: [
          analysisData?.content?.headings?.items?.h6?.length,
          analysisData?.content?.headings?.items?.h5?.length,
          analysisData?.content?.headings?.items?.h4?.length,
          analysisData?.content?.headings?.items?.h3?.length,
          analysisData?.content?.headings?.items?.h2?.length,
          analysisData?.content?.headings?.items?.h1?.length
        ]
      }
    ],
    xAxis: {
      type: 'value'
    },
    yAxis: {
      type: 'category',
      data: ['h6', 'h5', 'h4', 'h3', 'h2', 'h1']
    },
    tooltip: {
      axisPointer: {
        type: 'shadow'
      }
    }
  };

  return (
    <>
      {/* <button onClick={toPDF}>PDF</button> */}
      <style>
        {`
          #audit section {
            padding: 16px !important;
          }
          #audit h1, #audit h2, #audit h3 {
            margin-bottom: 0 !important;
            padding-bottom: 0.5rem !important;
          }
          #audit hr {
            margin: 0 !important;
            padding-bottom: 1rem !important;
          }
          #audit .card.my-3 {
            margin: 0 !important;
          }
        `}
      </style>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: 'calc(100vh - 132px)'
        }}
      >
        <Spinner />
      </div>
      <div ref={targetRef} id="audit" style={{ width: '794px' }}>
        <h1 className="text-center">Audit de l'analyse de page</h1>
        <hr />
        <h3 className="fs-1 text-center">Score total</h3>
        <EChartsReactCore echarts={echarts} option={scoreChartOption} />
        <hr />
        <h2>SEO</h2>
        <h3 className="fs-1">Informations principales</h3>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.seo?.document_title?.title}
            state={analysisData?.seo?.document_title?.status}
            description={analysisData?.seo?.document_title?.message}
          >
            <div className="mt-3">
              <p className="mb-0">
                <strong>
                  {analysisData?.seo?.document_title?.items != null &&
                  analysisData?.seo?.document_description?.items[0]
                    .document_title != (null || '')
                    ? analysisData?.seo?.document_title?.items[0].document_title
                    : t('No result found')}
                </strong>
              </p>
            </div>
          </CustomInfoCard>
        </div>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.seo?.document_description?.title}
            state={analysisData?.seo?.document_description?.status}
            description={analysisData?.seo?.document_description?.message}
          >
            <div className="mt-3">
              <p className="mb-0">
                <strong>
                  {analysisData?.seo?.document_description?.items != null &&
                  analysisData?.seo?.document_description?.items[0]
                    .meta_description != (null || '')
                    ? analysisData?.seo?.document_description?.items[0]
                        .meta_description
                    : t('No result found')}
                </strong>
              </p>
            </div>
          </CustomInfoCard>
        </div>
        <h3 className="fs-1">Meta Tags</h3>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.seo?.meta_tags?.title}
            state={analysisData?.seo?.meta_tags?.status}
            description={analysisData?.seo?.meta_tags?.message}
          />
        </div>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.seo?.meta_tag_robot?.title}
            state={analysisData?.seo?.meta_tag_robot?.status}
            description={analysisData?.seo?.meta_tag_robot?.message}
          />
        </div>
        <h3 className="fs-1">Indexation</h3>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.seo?.robots?.title}
            state={analysisData?.seo?.robots?.status}
            description={analysisData?.seo?.robots?.message}
          />
        </div>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.seo?.sitemap?.title}
            state={analysisData?.seo?.sitemap?.status}
            description={analysisData?.seo?.sitemap?.message}
          />
        </div>
        <h3 className="fs-1">Contenu dupliqué</h3>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.seo?.canonical?.title}
            state={analysisData?.seo?.canonical?.status}
            description={analysisData?.seo?.canonical?.message}
          />
        </div>
        <h3 className="fs-1">Multimédia</h3>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.seo?.multimedia?.title}
            state={analysisData?.seo?.multimedia?.status}
            description={analysisData?.seo?.multimedia?.message}
          />
        </div>
        <hr />
        <h2>Contenu</h2>
        <h3 className="fs-1">Contenu et Mots-Clés</h3>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.content?.content_ratio?.title}
            state={analysisData?.content?.content_ratio?.status}
            description={analysisData?.content?.content_ratio?.message}
          >
            <EChartsReactCore echarts={echarts} option={contentRatioOption} />
          </CustomInfoCard>
        </div>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.content?.keyword_qty?.title}
            state={analysisData?.content?.keyword_qty?.status}
            description={analysisData?.content?.keyword_qty?.message}
          >
            <EChartsReactCore echarts={echarts} option={keywordQtyOption} />
          </CustomInfoCard>
        </div>
        <h3 className="fs-1">Sémantique</h3>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.content?.headings?.title}
            state={analysisData?.content?.headings?.status}
            description={analysisData?.content?.headings?.message}
          >
            <EChartsReactCore echarts={echarts} option={headingsOption} />
          </CustomInfoCard>
        </div>
        <h3 className="fs-1">Micro Data</h3>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.content?.microdata?.title}
            state={analysisData?.content?.microdata?.status}
            description={analysisData?.content?.microdata?.message}
          />
        </div>
        <hr />
        <h2>Technique</h2>
        <h3 className="fs-1">Normalisation</h3>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.technique?.doctype?.title}
            state={analysisData?.technique?.doctype?.status}
            description={analysisData?.technique?.doctype?.message}
          />
        </div>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.technique?.encoding?.title}
            state={analysisData?.technique?.encoding?.status}
            description={analysisData?.technique?.encoding?.message}
          />
        </div>
        <h3 className="fs-1">Qualité de la mise en page</h3>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.technique?.html_in_comment?.title}
            state={analysisData?.technique?.html_in_comment?.status}
            description={analysisData?.technique?.html_in_comment?.message}
          >
            <div className="mt-3">
              {analysisData?.technique?.html_in_comments?.items?.length > 0 ? (
                <p className="mb-0" style={{ whiteSpace: 'pre-line' }}>
                  {analysisData?.technique?.html_in_comment?.items}
                </p>
              ) : (
                <p className="mb-0">
                  <strong>{t('No result found')}</strong>
                </p>
              )}
            </div>
          </CustomInfoCard>
        </div>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.technique?.frames_and_iframes?.title}
            state={analysisData?.technique?.frames_and_iframes?.status}
            description={analysisData?.technique?.frames_and_iframes?.message}
          />
        </div>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.technique?.flash_content?.title}
            state={analysisData?.technique?.flash_content?.status}
            description={analysisData?.technique?.flash_content?.message}
          />
        </div>
        <h3 className="fs-1">Configuration du Serveur</h3>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.technique?.web_server?.title}
            state={analysisData?.technique?.web_server?.status}
            description={analysisData?.technique?.web_server?.message}
          >
            <div className="mt-3">
              <p className="mb-0">
                <strong>Serveur&nbsp;: </strong>
                {analysisData?.technique?.web_server?.display_value ?? '-'}
              </p>
            </div>
          </CustomInfoCard>
        </div>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.technique?.ipv6?.title}
            state={analysisData?.technique?.ipv6?.status}
            description={analysisData?.technique?.ipv6?.message}
          />
        </div>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.technique?.dns_infos?.title}
            state={analysisData?.technique?.dns_infos?.status}
            description={analysisData?.technique?.dns_infos?.message}
          >
            <div className="mt-3">
              <p>
                <strong>IP&nbsp;: </strong>
                {analysisData?.technique?.dns_infos?.items?.ip ?? '-'}
              </p>
              <p>
                <strong>Nom de domaine&nbsp;: </strong>
                {analysisData?.technique?.dns_infos?.items?.domain_name ?? '-'}
              </p>
              <p>
                <strong>DNSSEC&nbsp;: </strong>
                {analysisData?.technique?.dns_infos?.items?.dnssec?.length > 0
                  ? analysisData?.technique?.dns_infos?.items?.dnssec?.map(
                      (item, index, array) => (
                        <span
                          key={`pageAnalysisDetailsTechniqueDnsInfosDnssec${index}`}
                        >
                          {item}
                          {index != array.length - 1 ? ', ' : ''}
                        </span>
                      )
                    )
                  : '-'}
              </p>
              <p>
                <strong>Registraire&nbsp;: </strong>
                {analysisData?.technique?.dns_infos?.items?.registrar ?? '-'}
              </p>
              <p>
                <strong>Date de création&nbsp;: </strong>
                {analysisData?.technique?.dns_infos?.items?.creation_date !=
                null
                  ? new Date(
                      analysisData?.technique?.dns_infos?.items?.creation_date
                    ).toLocaleString()
                  : '-'}
              </p>
              <p>
                <strong>Date d'expiration&nbsp;: </strong>
                {analysisData?.technique?.dns_infos?.items?.expiration_date !=
                null
                  ? new Date(
                      analysisData?.technique?.dns_infos?.items?.expiration_date
                    ).toLocaleString()
                  : '-'}
              </p>
              <p>
                <strong>Nom&nbsp;: </strong>
                {analysisData?.technique?.dns_infos?.items?.name ?? '-'}
              </p>
              <p>
                <strong>Organisation&nbsp;: </strong>
                {analysisData?.technique?.dns_infos?.items?.org ?? '-'}
              </p>
              <p>
                <strong>Adresse&nbsp;: </strong>
                {analysisData?.technique?.dns_infos?.items?.address ?? '-'}
              </p>
              <p>
                <strong>Ville&nbsp;: </strong>
                {analysisData?.technique?.dns_infos?.items?.city ?? '-'}
              </p>
              <p>
                <strong>État&nbsp;: </strong>
                {analysisData?.technique?.dns_infos?.items?.state ?? '-'}
              </p>
              <p>
                <strong>Code postal du registrant&nbsp;: </strong>
                {analysisData?.technique?.dns_infos?.items
                  ?.registrant_postal_code ?? '-'}
              </p>
              <p className="mb-0">
                <strong>Pays&nbsp;: </strong>
                {analysisData?.technique?.dns_infos?.items?.country ?? '-'}
              </p>
            </div>
          </CustomInfoCard>
        </div>
        <h3 className="fs-1">Sécurité</h3>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.technique?.emails?.title}
            state={analysisData?.technique?.emails?.status}
            description={analysisData?.technique?.emails?.message}
          />
        </div>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.technique?.xss_protection?.title}
            state={analysisData?.technique?.xss_protection?.status}
            description={analysisData?.technique?.xss_protection?.message}
          />
        </div>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.technique?.content_security_policy?.title}
            state={analysisData?.technique?.content_security_policy?.status}
            description={
              analysisData?.technique?.content_security_policy?.message
            }
          />
        </div>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.technique?.certificate?.title}
            state={analysisData?.technique?.certificate?.status}
            description={analysisData?.technique?.certificate?.message}
          >
            <div className="mt-3">
              {analysisData?.technique?.certificate?.items?.certificate_infos
                ?.details != null ? (
                <>
                  <h4 className="fs-1 fw-normal">
                    Informations à propos du certificat
                  </h4>
                  <h5 className="fs-1 fw-light ms-2 mt-3">- Émetteur</h5>
                  {analysisData?.technique?.certificate?.items?.certificate_infos?.details?.issuer?.map(
                    (detail, index) => (
                      <p
                        key={`pageAnalysisDetailsTechniqueCertificateInfosIssuer${index}`}
                        className="ms-4"
                      >
                        <strong>{detail[0][0]} : </strong>
                        {detail[0][1]}
                      </p>
                    )
                  )}
                  <h5 className="fs-1 fw-light ms-2 mt-3">- Sujet</h5>
                  {analysisData?.technique?.certificate?.items?.certificate_infos?.details?.subject?.map(
                    (detail, index) => (
                      <p
                        key={`pageAnalysisDetailsTechniqueCertificateInfosSubject${index}`}
                        className="ms-4"
                      >
                        <strong>{detail[0][0]} : </strong>
                        {detail[0][1]}
                      </p>
                    )
                  )}
                  <p>
                    <strong>Version : </strong>
                    {analysisData?.technique?.certificate?.items
                      ?.certificate_infos?.details?.version ??
                      t('No result found')}
                  </p>
                  <p className="mb-0">
                    <strong>Date d'expiration : </strong>
                    {analysisData?.technique?.certificate?.items
                      ?.certificate_infos?.details?.expiration_date != null
                      ? new Date(
                          analysisData?.technique?.certificate?.items?.certificate_infos?.details?.expiration_date
                        ).toLocaleString()
                      : t('No result found')}
                  </p>
                </>
              ) : (
                t('No result found')
              )}
            </div>
          </CustomInfoCard>
        </div>
        <hr />
        <h2>Performance</h2>
        <h3 className="fs-1">Optimisation du Téléchargement</h3>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.performance?.total_byte_weight?.title}
            state={analysisData?.performance?.total_byte_weight?.status}
            description={analysisData?.performance?.total_byte_weight?.message}
          >
            <div className="mt-3">
              <p className="mb-0">
                <strong>Valeur&nbsp;: </strong>
                {analysisData?.performance?.total_byte_weight
                  ?.total_byte_weight != null
                  ? parseInt(
                      analysisData?.performance?.total_byte_weight
                        ?.total_byte_weight / 10000
                    ) /
                      100 +
                    ' Mo'
                  : t('No result found')}
              </p>
            </div>
          </CustomInfoCard>
        </div>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.performance?.execution_time?.title}
            state={analysisData?.performance?.execution_time?.status}
            description={analysisData?.performance?.execution_time?.message}
          >
            <div className="mt-3">
              <p className="mb-0">
                <strong>Valeur&nbsp;: </strong>
                {analysisData?.performance?.execution_time
                  ?.execution_time_in_ms != null
                  ? analysisData?.performance?.execution_time
                      ?.execution_time_in_ms + ' ms'
                  : t('No result found')}
              </p>
            </div>
          </CustomInfoCard>
        </div>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.performance?.compression?.title}
            state={analysisData?.performance?.compression?.status}
            description={analysisData?.performance?.compression?.message}
          >
            <div className="mt-3">
              <p>
                <strong>Activée&nbsp;: </strong>
                {analysisData?.performance?.compression?.items?.activated
                  ? 'Oui'
                  : 'Non'}
              </p>
              <p>
                <strong>Type de compression&nbsp;: </strong>
                {analysisData?.performance?.compression?.items?.compress_type ??
                  t('No result found')}
              </p>
              <p className="mb-0">
                <strong>Taille en octets&nbsp;: </strong>
                {analysisData?.performance?.compression?.items?.size_in_bytes !=
                null
                  ? analysisData?.performance?.compression?.items
                      ?.size_in_bytes + ' octets'
                  : t('No result found')}
              </p>
            </div>
          </CustomInfoCard>
        </div>
        <h3 className="fs-1">Optimisation du Chargement</h3>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.performance?.script_in_head?.title}
            state={analysisData?.performance?.script_in_head?.status}
            description={analysisData?.performance?.script_in_head?.message}
          />
        </div>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.performance?.inline_javascript?.title}
            state={analysisData?.performance?.inline_javascript?.status}
            description={analysisData?.performance?.inline_javascript?.message}
          >
            <div className="mt-3">
              <p className="mb-0">
                <strong>Valeur&nbsp;: </strong>
                {analysisData?.performance?.inline_javascript?.items
                  ?.size_in_bytes ?? 0}{' '}
                octets
              </p>
            </div>
          </CustomInfoCard>
        </div>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.performance?.inline_styles?.title}
            state={analysisData?.performance?.inline_styles?.status}
            description={analysisData?.performance?.inline_styles?.message}
          >
            <div className="mt-3">
              <p className="mb-0">
                <strong>Valeur&nbsp;: </strong>
                {analysisData?.performance?.inline_styles?.items?.count ??
                  0}{' '}
                balises
              </p>
            </div>
          </CustomInfoCard>
        </div>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.performance?.style_tags?.title}
            state={analysisData?.performance?.style_tags?.status}
            description={analysisData?.performance?.style_tags?.message}
          >
            <div className="mt-3">
              <p className="mb-0">
                <strong>Valeur&nbsp;: </strong>
                {analysisData?.performance?.style_tags?.items?.count ?? 0}{' '}
                balises
              </p>
            </div>
          </CustomInfoCard>
        </div>
        <hr />
        <h2>Accessibilié</h2>
        <h3 className="fs-1">Accessibilité</h3>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.accessibility?.domain_length?.title}
            state={analysisData?.accessibility?.domain_length?.status}
            description={analysisData?.accessibility?.domain_length?.message}
          />
        </div>
        <h3 className="fs-1">Visibilité</h3>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.accessibility?.bg_sound_tag?.title}
            state={analysisData?.accessibility?.bg_sound_tag?.status}
            description={analysisData?.accessibility?.bg_sound_tag?.message}
          />
        </div>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.accessibility?.input_no_label?.title}
            state={analysisData?.accessibility?.input_no_label?.status}
            description={analysisData?.accessibility?.input_no_label?.message}
          />
        </div>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.accessibility?.favicon?.title}
            state={analysisData?.accessibility?.favicon?.status}
            description={analysisData?.accessibility?.favicon?.message}
          />
        </div>
        <h3 className="fs-1">Internationalisation</h3>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.accessibility?.lang?.title}
            state={analysisData?.accessibility?.lang?.status}
            description={analysisData?.accessibility?.lang?.message}
          />
        </div>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.accessibility?.opengraphs?.title}
            state={analysisData?.accessibility?.opengraphs?.status}
            description={analysisData?.accessibility?.opengraphs?.message}
          />
        </div>
        <h3 className="fs-1">Compatibilité Mobile</h3>
        <div className="pb-3">
          <CustomInfoCard
            content={analysisData?.accessibility?.meta_viewport?.title}
            state={analysisData?.accessibility?.meta_viewport?.status}
            description={analysisData?.accessibility?.meta_viewport?.message}
          />
        </div>
      </div>
    </>
  );
};

export default PageAnalysisDetailsAudit;
